<template>
  <div class="container">
    <div class="row row-cols-1 row-cols-lg-4">
      <div class="col mt-3 border-bottom">
        <label class="text-upper">Sunbed state</label>
        <div>
          <h5 class="overflow-ellipsis">
            {{ (equipment.controller.state !== null ? equipment.controller.state : '-') }}
          </h5>
        </div>
      </div>
      <div class="col mt-3 border-bottom">
        <label class="text-upper">Sunbed active errors (bits)</label>
        <div>
          <h5 class="overflow-ellipsis">
            {{ (equipment.controller.active_errors !== null ? equipment.controller.active_errors : '-') }}
          </h5>
        </div>
      </div>
      <div class="col mt-3 border-bottom">
        <label class="text-upper">Controller serial</label>
        <div>
          <h5 class="overflow-ellipsis">
            {{ (equipment.controller.serial !== null ? equipment.controller.serial : '-') }}
          </h5>
        </div>
      </div>
      <div class="col mt-3 border-bottom">
        <label class="text-upper">Controller communication</label>
        <div>
          <h5 class="overflow-ellipsis">
            {{ (typeof equipment.controller.communication.timeout === 'boolean' ? (equipment.controller.communication.timeout === false ? 'Communicating' : 'Interrupted') : '-') }}
          </h5>
        </div>
      </div>

      <div class="col mt-3 border-bottom">
        <label class="text-upper">Sunbed timestamp</label>
        <div>
          <h5 class="overflow-ellipsis">
            {{ (equipment.controller.timestamp || '-') }}
          </h5>
        </div>
      </div>
      <div class="col mt-3 border-bottom">
        <label class="text-upper">Server timestamp</label>
        <div>
          <h5 class="overflow-ellipsis">
            {{ (equipment.device.last_ping ? (equipment.device.last_ping / 1000).toFixed(0) : '-') }}
          </h5>
        </div>
      </div>
      <div class="col mt-3 border-bottom">
        <label class="text-upper">Time deviation</label>
        <div class="row">
          <h5 class="col overflow-ellipsis">
            {{ timeDeviationFormatted || '-' }}
          </h5>

          <div class="col-auto">
            <i class="svg-icon text-info cursor-pointer" v-b-tooltip.hover="`For indicative purposes only. Disregard if sunbed controller is disconnected from MyLuxura Module.`">
              <font-awesome-icon :icon="['fas', 'info-circle']" />
            </i>
          </div>
        </div>
      </div>
    </div>

    <!-- begin::temperatures for Luxura Jewel -->
    <template v-if="equipment.model === 'luxura_jewel'">
      <div class="row mt-3">
        <div class="col mt-3 border-bottom">
          <label class="text-upper text-center d-block">Luxura Jewel Temperatures</label>
          <div>
            <table class="table table-border">
              <thead>
                <tr>
                  <th />
                  <th>Temperature 1</th>
                  <th>Temperature 2</th>
                  <th>Temperature 3</th>
                  <th>Temperature 4</th>
                  <th>Temperature 5</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="font-weight-bold">Driver 1 Module 0</td>
                  <td v-html="getHumanReadableTemperatureSensorValue('T1') || '?'" />
                  <td v-html="getHumanReadableTemperatureSensorValue('T2') || '?'" />
                  <td v-html="getHumanReadableTemperatureSensorValue('T3') || '?'" />
                  <td v-html="getHumanReadableTemperatureSensorValue('T4') || '?'" />
                  <td v-html="getHumanReadableTemperatureSensorValue('T5') || '?'" />
                </tr>
                <tr>
                  <td class="font-weight-bold">Driver 1 Module 1</td>
                  <td v-html="getHumanReadableTemperatureSensorValue('T6') || '?'" />
                  <td v-html="getHumanReadableTemperatureSensorValue('T7') || '?'" />
                  <td v-html="getHumanReadableTemperatureSensorValue('T8') || '?'" />
                  <td v-html="getHumanReadableTemperatureSensorValue('T9') || '?'" />
                  <td v-html="getHumanReadableTemperatureSensorValue('T10') || '?'" />
                </tr>
                <tr>
                  <td class="font-weight-bold">Driver 1 Module 2</td>
                  <td v-html="getHumanReadableTemperatureSensorValue('T11') || '?'" />
                  <td v-html="getHumanReadableTemperatureSensorValue('T12') || '?'" />
                  <td v-html="getHumanReadableTemperatureSensorValue('T13') || '?'" />
                  <td v-html="getHumanReadableTemperatureSensorValue('T14') || '?'" />
                  <td v-html="getHumanReadableTemperatureSensorValue('T15') || '?'" />
                </tr>
                <tr>
                  <td class="font-weight-bold">Driver 1 Module 3</td>
                  <td v-html="getHumanReadableTemperatureSensorValue('T16') || '?'" />
                  <td v-html="getHumanReadableTemperatureSensorValue('T17') || '?'" />
                  <td v-html="getHumanReadableTemperatureSensorValue('T18') || '?'" />
                  <td v-html="getHumanReadableTemperatureSensorValue('T19') || '?'" />
                  <td v-html="getHumanReadableTemperatureSensorValue('T20') || '?'" />
                </tr>
              </tbody>
              <tfoot v-if="temperature_log">
                <tr>
                  <td colspan="42" class="text-muted text-right">
                    <span class="d-block">Controller state at time of log: {{ temperature_log.controller_state }}</span>
                    <span class="d-block">Controller serial at time of log: {{ temperature_log.controller_serial }}</span>
                    <span class="d-block">Timestamp of log: {{ $moment(temperature_log.log_date) }}</span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </template>
    <!-- end::temperatures for Luxura Jewel -->
  </div>
</template>

<script>
import moment from 'moment-timezone';

import Equipment from '@/libs/classes/equipment';
import Device from '@/libs/classes/device';

export default {
  props: {
    equipment: {
      type: Equipment,
      default: null,
    },
    device: {
      type: Device,
      default: null,
    },
  },
  data () {
    return {
      temperature_log: undefined,
    };
  },

  computed: {
    timezone () {
      return (this.equipment && this.equipment.location && this.equipment.location.timezone) || 'UTC';
    },

    timeDeviation () {
      if (!this.equipment || !this.equipment.device || !this.equipment.device.last_ping) {
        return null;
      }

      if (!this.equipment.controller || !this.equipment.controller.timestamp) {
        return null;
      }

      return moment.utc(this.equipment.controller.timestamp * 1000).subtract(moment.tz(this.timezone).utcOffset(), 'minutes').diff(moment.utc(this.equipment.device.last_ping));
    },

    timeDeviationFormatted () {
      const deviation = this.timeDeviation;
      if (!deviation) {
        return null;
      }

      const duration = moment.duration(this.timeDeviation); // In milliseconds
      if (duration.get('hours') !== 0) {
        return `${duration.get('hours')} hours, ${duration.get('minutes')} minutes, ${duration.get('seconds')} seconds`;
      }

      if (duration.get('minutes') !== 0) {
        return `${duration.get('minutes')} minutes, ${duration.get('seconds')} seconds`;
      }

      return `${duration.get('seconds')} seconds`;
    },
  },

  async mounted () {
    await this.onMount();
  },

  methods: {
    async onMount () {
      try {
        if (this.equipment.model === 'luxura_jewel') {
          this.$set(this, 'temperature_log', await this.getLatestTemperatureLog());
        }
      } catch (e) {
        this.$errors.handle(e);
        console.error(e);
      }
    },
    async getLatestTemperatureLog () {
      const response = await this.$ws.get(`${Equipment.uri}/${this.equipment._meta.guid}/temperatures`, {
        query: {
          limit: 1,
          sort: 'log_date|desc',
        },
      });

      if (response.length === 0) {
        return undefined;
      }

      return response[0];
    },

    getTemperatureSensor (sensor) {
      if (sensor === undefined) {
        return undefined;
      }

      if (this.temperature_log === undefined) {
        return undefined;
      }

      return this.temperature_log.temperatures.find((row) => row.sensor === sensor);
    },
    getTemperatureSensorValue (sensor) {
      const sensorObject = this.getTemperatureSensor(sensor);
      if (sensorObject === undefined) {
        return undefined;
      }

      return sensorObject.value;
    },
    getHumanReadableTemperatureSensorValue (sensor) {
      const sensorValue = this.getTemperatureSensorValue(sensor);
      if (sensorValue === undefined) {
        return undefined;
      }

      return `${(sensorValue / 10).toFixed(1)} &deg;C`;
    },
  },
};
</script>
